import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"

const StyledAboutHeader = styled.h2`
  color: #333;
  flex-shrink: 0;
  font-size: 1.4em;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 0.3em;
`;

const StyledMiddleHeader = styled.h3`
  color: #333;
  flex-shrink: 0;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 0.3em;
`;

const AboutPage = () => (
  <Layout>
    <StyledAboutHeader>Welcome to Plastic Fake Tree</StyledAboutHeader>
    <p>This site brings you "news" from all over the world related to environment, environmental protection, politics, activism and even entertainment.</p>
    <p>All news you see here are fiction, that is, they are not true.</p>
    <StyledMiddleHeader>...however</StyledMiddleHeader>
    <p>Behind everything you see here there is some truth, some actual problem with the world. It causes anxiety in many. This site tries to bring some sense into all of this.</p>
    <p>Hopefully these "news" will make you think about things you have not thought about before, or maybe question what people in power are saying or doing.</p>
  </Layout>
)

export default AboutPage